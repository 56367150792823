import { isArray, isObject, toDash } from '@slideslive/fuse-kit/utils';

import { appendValue, setDataAttributes } from './utils';

function generateParamsString(controller, paramName, param, { output }) {
  const key = `${controller}-${toDash(paramName)}-param`;

  appendValue(output, key, param);
}

function generateParamsStrings(params, { output = {}, controller = null } = {}) {
  if (!params) return output;

  if (isArray(params)) {
    for (const param of params) {
      generateParamsStrings(param, { output, controller });
    }

    return output;
  }

  if (!isObject(params)) {
    console.warn('[STIMULUS]: invalid stimulus param data.');
    return output;
  }

  if (controller) {
    for (const [paramName, param] of Object.entries(params)) {
      generateParamsString(controller, paramName, param, { output });
    }

    return output;
  }

  for (const [c, param] of Object.entries(params)) {
    generateParamsStrings(param, { output, controller: c });
  }

  return output;
}

function setStimulusOutlet(element, params) {
  const data = generateParamsStrings(params);

  setDataAttributes(element, data);
}

export default setStimulusOutlet;

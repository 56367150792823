import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  isTurboPreview = false;

  constructor(context) {
    super(context);

    // use this instead of getter to fix a bug where the attribute in disconnect function is already from the new page
    this.isTurboPreview = document.documentElement.hasAttribute('data-turbo-preview');
  }

  dispatchOnWindow(eventName, { detail = {}, bubbles = true, cancelable = true, prefix = undefined } = {}) {
    return this.dispatch(eventName, { target: window, detail, bubbles, cancelable, prefix });
  }

  dispatchOnDocument(eventName, { detail = {}, bubbles = true, cancelable = true, prefix = undefined } = {}) {
    return this.dispatch(eventName, { target: document, detail, bubbles, cancelable, prefix });
  }

  componentController() {
    return this.findComponentController(this.element);
  }

  findComponentController(componentElement) {
    return this.findControllerByName(componentElement.dataset.componentController, {
      useQuerySelector: false,
      element: componentElement,
    });
  }

  findComponentChildController(controller, { componentElement = this.element } = {}) {
    const fullControllerName = `${componentElement.dataset.componentController}--${controller}`;

    return this.findControllerByName(fullControllerName, {
      useQuerySelector: false,
      element: componentElement,
    });
  }

  findControllerOnElement(element, { controller = null } = {}) {
    return this.findControllerByName(controller || element.dataset.controller, {
      useQuerySelector: false,
      element,
    });
  }

  findControllerByName(controller, { useQuerySelector = true, element = this.element } = {}) {
    if (useQuerySelector) {
      element = element.querySelector(`[data-controller~="${controller}"]`);
    }

    return this.application.getControllerForElementAndIdentifier(element, controller);
  }

  findAllControllersByName(controller, { element = this.element } = {}) {
    const controllerElements = element.querySelectorAll(`[data-controller*="${controller}"]`);

    return Array.from(controllerElements).map((el) =>
      this.application.getControllerForElementAndIdentifier(el, controller),
    );
  }

  findParentControllerByName(controller, { hideWarning = false } = {}) {
    if (!hideWarning) {
      console.warn(
        'Use ApplicationController#dispatch and listen for dispatched event instead of finding parent controller.',
      );
    }

    const element = this.element.closest(`[data-controller~="${controller}"]`);
    return this.application.getControllerForElementAndIdentifier(element, controller);
  }

  fetchTurboStream(resource, options = {}) {
    const abortController = new AbortController();
    const abort = () => abortController.abort('AbortFetchTurboStream');

    const request = fetch(resource, {
      method: 'GET',
      credentials: 'include',
      ...options,
      headers: {
        ...options.headers,
        Accept: 'text/vnd.turbo-stream.html',
      },
      signal: abortController.signal,
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
          throw new Error(response.statusText);
        }

        return response.text();
      })
      .then((html) => {
        if (html) {
          window.Turbo.renderStreamMessage(html);
        }
      })
      .catch((error) => {
        if (error.name === 'AbortFetchTurboStream') {
          return;
        }

        throw error;
      });

    return { request, abortController, abort };
  }

  get wasTurboPreview() {
    return document.documentElement.hasAttribute('data-was-turbo-preview');
  }
}

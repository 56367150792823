import { isArray, isObject, toDash } from '@slideslive/fuse-kit/utils';

import { appendValue, setDataAttributes } from './utils';

function generateValuesString(controller, valueName, value, { output }) {
  const key = `${controller}-${toDash(valueName)}-value`;

  appendValue(output, key, value);
}

function generateValuesStrings(values, { output = {}, controller = null } = {}) {
  if (!values) return output;

  if (isArray(values)) {
    for (const value of values) {
      generateValuesStrings(value, { output, controller });
    }

    return output;
  }

  if (!isObject(values)) {
    console.warn('[STIMULUS]: invalid stimulus value data.');
    return output;
  }

  if (controller) {
    for (const [valueName, value] of Object.entries(values)) {
      generateValuesString(controller, valueName, value, { output });
    }

    return output;
  }

  for (const [c, value] of Object.entries(values)) {
    generateValuesStrings(value, { output, controller: c });
  }

  return output;
}

function setStimulusValue(element, values) {
  const data = generateValuesStrings(values);

  setDataAttributes(element, data);
}

export default setStimulusValue;

import { isArray, isObject, toDash } from '@slideslive/fuse-kit/utils';

import { appendValue, setDataAttributes } from './utils';

function generateOutletsString(controller, outletName, outlet, { output }) {
  const key = `${controller}-${toDash(outletName)}-outlet`;

  appendValue(output, key, outlet);
}

function generateOutletsStrings(outlets, { output = {}, controller = null } = {}) {
  if (!outlets) return output;

  if (isArray(outlets)) {
    for (const outlet of outlets) {
      generateOutletsStrings(outlet, { output, controller });
    }

    return output;
  }

  if (!isObject(outlets)) {
    console.warn('[STIMULUS]: invalid stimulus outlet data.');
    return output;
  }

  if (controller) {
    for (const [outletName, outlet] of Object.entries(outlets)) {
      generateOutletsString(controller, outletName, outlet, { output });
    }

    return output;
  }

  for (const [c, outlet] of Object.entries(outlets)) {
    generateOutletsStrings(outlet, { output, controller: c });
  }

  return output;
}

function setStimulusOutlet(element, outlets) {
  const data = generateOutletsStrings(outlets);

  setDataAttributes(element, data);
}

export default setStimulusOutlet;

import { isArray, isObject, toDash } from '@slideslive/fuse-kit/utils';

import { appendValue, setDataAttributes } from './utils';

function generateClassesString(controller, className, klass, { output }) {
  const key = `${controller}-${toDash(className)}-class`;

  if (isArray(klass)) {
    for (const c of klass) {
      appendValue(output, key, c);
    }

    return;
  }

  appendValue(output, key, klass);
}

function generateClassesStrings(classes, { output = {}, controller = null } = {}) {
  if (!classes) return output;

  if (isArray(classes)) {
    for (const klass of classes) {
      generateClassesStrings(klass, { output, controller });
    }

    return output;
  }

  if (!isObject(classes)) {
    console.warn('[STIMULUS]: invalid stimulus class data.');
    return output;
  }

  if (controller) {
    for (const [className, klass] of Object.entries(classes)) {
      generateClassesString(controller, className, klass, { output });
    }

    return output;
  }

  for (const [c, klass] of Object.entries(classes)) {
    generateClassesStrings(klass, { output, controller: c });
  }

  return output;
}

function setStimulusClasses(element, classes) {
  const data = generateClassesStrings(classes);

  setDataAttributes(element, data);
}

export default setStimulusClasses;
